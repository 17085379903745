import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "subbody modulewrap"
};
const _hoisted_2 = {
  key: 0,
  class: "subbannerwrap"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "subbannertitle"
};
const _hoisted_5 = {
  key: 1,
  class: "courselist"
};
const _hoisted_6 = {
  class: "coursetitle"
};
const _hoisted_7 = {
  key: 0,
  class: "courseimgcon"
};
const _hoisted_8 = {
  class: "courseimg"
};
const _hoisted_9 = ["src"];
const _hoisted_10 = ["innerHTML"];
const _hoisted_11 = ["innerHTML"];
import { FFmixin } from "@/mixin/mixin.js";
export default {
  __name: 'course',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      setTitleByAlias
    } = FFmixin();
    const route = useRoute();
    const FFdata = reactive({
      infodata: [],
      infoDetail: [],
      root: '/course'
    });
    const getData = async () => {
      let alias = route.params.sub;
      setTitleByAlias(4, alias, navSub => {
        FFdata.root = '/course/' + navSub.alias;
        let id = route.params.id;
        if (id) {
          proxy.$common.fetch({
            'act': 'telfort_coursedetail',
            'exts': 'id=' + id,
            msg: ''
          }, res => {
            FFdata.infoDetail = res.infodata;
          });
        } else {
          getNewsList(navSub);
        }
      });
    };
    const getNewsList = navSub => {
      proxy.$common.fetch({
        'act': 'telfort_course',
        'exts': 'id=' + navSub.kv,
        msg: ''
      }, res => {
        FFdata.infodata = res.infodata;
      });
    };
    onMounted(() => {
      getData();
    });
    return (_ctx, _cache) => {
      const _component_fflink = _resolveComponent("fflink");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$common.basic.isArrayExist(_unref(FFdata).infodata) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(FFdata).infodata, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "subbannerlist zoom",
          key: item.id
        }, [_createElementVNode("img", {
          src: item.imgcover
        }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createVNode(_component_fflink, {
          info: item,
          root: _unref(FFdata).root,
          isOverDot: false
        }, null, 8, ["info", "root"])])]);
      }), 128))])) : _createCommentVNode("", true), _ctx.$common.basic.isArrayExist(_unref(FFdata).infoDetail) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(FFdata).infoDetail, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "courseinfo",
          key: item.id
        }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString(_ctx.$common.langInfo(item.title, item.title_en)), 1)]), item.img ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("img", {
          src: item.img
        }, null, 8, _hoisted_9)]), _createElementVNode("div", {
          class: "courseimginfo editor",
          innerHTML: _ctx.$common.langInfo(item.concn, item.conen)
        }, null, 8, _hoisted_10)])) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "coursecon realwrap editor",
          innerHTML: _ctx.$common.langInfo(item.concn, item.conen)
        }, null, 8, _hoisted_11))]);
      }), 128))])) : _createCommentVNode("", true)]);
    };
  }
};